<template>
  <div class="content content-full content-boxed">
    <div v-show="errorAction.update" class="alert alert-danger">{{ errorAction.update }}</div>
    <div class="block block-rounded">
      <div class="block-content">
        <h3>Edit Supplier</h3>
        <SupplierForm v-model="form" :error="errorAction.update || {}" />
        <div class="text-right pb-4">
          <button type="submit" class="btn btn-primary" :disabled="loadingAction.update" @click="onUpdateSupplier">
            <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
            <span v-else><i class="fa fa-check-circle mr-1"></i> Update Supplier</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import SupplierForm from '@/components/forms/SupplierForm';

export default {
  name: 'SupplierEdit',
  components: {
    SupplierForm
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
      loadingAction: 'supplier/loadingAction',
      errorAction: 'supplier/errorAction'
    })
  },
  async mounted() {
    this.form = this.supplier;
  },
  methods: {
    ...mapActions({
      updateSupplier: 'supplier/update'
    }),
    async onUpdateSupplier() {
      await this.updateSupplier({ id: this.$route.params.id, data: this.form });
      this.$toasted.success('Supplier updated successfully!', { position: 'bottom-right', duration: 3000 });
    }
  }
};
</script>
